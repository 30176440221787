/* squarespace */
#root {
  position: relative;
  z-index: 1000;
}

#header {
  display: none;
}

/* blogs */
.blog-meta-delimiter,
.blog-meta-secondary {
  display: none !important;
}

.blog-basic-grid--container>div:first-child {
  position: relative;
}

.blog-meta-section,
.summary-metadata-container {
  margin-bottom: unset !important;
  position: absolute;
  left: 0;
  bottom: 0;
}

.blog-basic-grid .blog-categories-list a,
.blog-basic-grid .blog-author,
.blog-basic-grid .blog-date,
.blog-basic-grid .blog-meta-delimiter {
  font-family: "Campton", "Roboto", "Helvetica Neue", Helvetica, Arial, Sans-Serif;
}

.sqs-block-summary-v2 * {
  font-family: "Campton", "Roboto", "Helvetica Neue", Helvetica, Arial, Sans-Serif;
}

.blog-item-wrapper .blog-item-title h1.entry-title {
  font-family: "Campton", "Roboto", "Helvetica Neue", Helvetica, Arial, Sans-Serif;
}

.sqs-block-summary-v2 .summary-title {
  font-weight: 600 !important;
}

@media screen and (min-width: 1089.92px) {
  [data-collection-type^="blog"] .item-pagination-title {
    font-size: calc(1rem) !important;
  }

  .blog-item-wrapper .blog-item-title h1.entry-title {
    font-size: calc(2 * 1rem);
  }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  [data-collection-type^="blog"] .item-pagination-title {
    font-size: calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 1rem);
  }
}

.sqs-block-html .sqs-block-content *:not(h1):not(h2):not(h3), .sqs-block-html .sqs-html-content *:not(h1):not(h2):not(h3) {
  text-decoration: none;
}

.summary-item-list {
  display: flex !important;
  flex-wrap: wrap;
}